import React, { useState } from 'react';
import './Home.css';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';



import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
// ----------------Swiper slider import------------------
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import "swiper/css/effect-fade";

function Community() {
    const [isMuted, setIsMuted] = useState(true);
    const [isActive, setActive] = useState("false");

    const ToggleClass = () => {
        setActive(!isActive);
    };
    return (
        <>

            <Header />
            {/* <!-- banner section --> */}
            <section className="oxfordBanner">
                <div>
                    <video className="sliderBgImage" autoPlay loop playsinline width="100%" muted={isMuted ? true : false}>
                        {/* <source src="assets/videos/OxfordVideo-Com.mp4" type="video/mp4" /> */}
                        <source src="https://res.cloudinary.com/dpj2oayjr/video/upload/v1711693095/MU_New_Site/iv3tdgjgarbau4qlm7h4.mp4" type="video/mp4" />
                    </video>
                </div>
                <div className="banner-content">
                    <h1>Community Challenges </h1>
                    <div className={isActive ? "mute" : "unmute"}>
                        <a onClick={() => { setIsMuted(!isMuted); ToggleClass(); }} className="muteBtn widthAuto" id="btnUnMute"><img className="unmuteIcon" src="/assets/images/unmute.svg" alt="unMuteIcon" />
                            <img className="muteIcon" src="/assets/images/mute.svg" alt="MuteIcon" /></a>
                    </div>
                </div>
            </section >
            {/* <!-- introduction --> */}
            < section className="intro-section communityIntro-section " >
                <div className="container">
                    <div className="introduction">
                        <h2 className="secTitle textBlack">Introduction</h2>
                        <p>The Oxford MUN, a beacon of diplomatic simulations worldwide, marks its groundbreaking entry into India at FLAME University, Pune, from 9th to 11th August 2024. Orchestrated by the MU20 School of Opportunity, this event is a fusion of Oxford's prestigious academic standards with the vibrant cultural diversity of India, offering an unparalleled MUN experience.
                        </p>
                    </div>
                </div>
            </section >
            {/* <!-- Why OxfordMUN India ? --> */}
            < section className="whyOxfordSec communityWhyOxfordSec" >
                <div className="container">
                    <div className="oxford-mun">
                        <h2 className="secTitle ">Why OxfordMUN India ?</h2>
                    </div>
                    <div className="inner-box">
                        <div className="row">
                            <div className="column">
                                <div className="oxford-box">
                                    <h3 className="textLOrg">Legacy Meets Opportunity</h3>
                                    <p className="textLOrg">For the first time in India, high school students (Classes 9th to 12th) have the chance to engage in a globally recognized platform for debate, diplomacy, and leadership.</p>
                                </div>
                                <div className="oxford-box">
                                    <h3 className="textLOrg">Empowerment Through Engagement</h3>
                                    <p className="textLOrg">With committee sizes limited to 70 delegates, OxfordMUN India ensures meaningful interaction, fostering a conducive environment for every participant to contribute effectively.</p>
                                </div>
                            </div>
                            <div className="column">
                                <div className="oxford-box">
                                    <h3 className="textLOrg">Comprehensive Learning Experience</h3>
                                    <p className="textLOrg">Beyond debates, the event features training sessions, Power Talks, and special events like the Masquerade Ball and Global Village Gala to enrich participants' journey.</p>
                                </div>
                                <div className="oxford-box">
                                    <h3 className="textLOrg">Recognition for the best delegates</h3>
                                    <p className="textLOrg">The Best Delegates will be awarded a 100% scholarship on the registration fee and accommodation to participate in OxfordMUN at Oxford, offering them international exposure and the chance to further their diplomatic engagement on a global stage.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            {/* <!-- Conference Details --> */}
            < section className="conference communityConference" >
                <div className="container">
                    <div className="confere-detail">
                        <h2 className="secTitle textBlack">Conference Details</h2>

                        <div className="row">
                            <div className="column">
                                <div className="confere-box">
                                    <h3 className="textBlack">Who</h3>
                                    <p>Students studying in classes </p><span className="bgColorText">9th to 12th can
                                        register</span>

                                </div>
                                <div className="confere-box m-0">
                                    <h3 className="textBlack">When and Where</h3>
                                    <span className="bgColorText">09th August 2024 - 10th August 2024 -</span>

                                    <p><span className="bgColorText"> 11th August 2024</span> at FLAMES University, Pune
                                    </p>
                                </div>
                            </div>
                            <div className="column confere-reg orgBg">
                                <div className="confere-regInner">
                                    <h3>Registration</h3>
                                    <p>To express your interest in joining, schools are
                                        requested to register by <span>30th April 2024.</span> Please note that the priority
                                        registrations for students will end on <span>29th June</span> , and final registration
                                        deadlines will be <span>16th July 2024.</span>
                                    </p>
                                    <a href="https://bit.ly/OxfordMUNRegistration" className="registerBtn" target="_blank"> Register Now </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <section className="communityFlameUni p-80-20">
                <div className="container">
                    <div className="communityFlameUni-outer">
                        <div className="communityFlameUni-innerLeft">
                            <img className="widthAuto" src="/assets/images/FlamesUnLogo.png" alt="FlamesUnLogo" />
                            <h3 className="textLOrg fw-600 fs-42 mb-10 ">FLAME University</h3>
                            <p className="fs-18 fw-400 textBlack">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                        </div>
                        <div className="communityFlameUni-innerRight">
                            <img className="universityImg" src="/assets/images/FlamesUniversity.png" alt="FlamesUniversity" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="experienceSec p-80-20 ">
                <div className="container-full">
                    <h2 className="secTitle textBlack">Things you experience</h2>

                    <Swiper breakpoints={{
                        1440: {
                            spaceBetween: 20,
                            slidesPerView: 5,
                        },
                        768: {
                            spaceBetween: 20,
                            slidesPerView: 3,

                        },
                        200: {
                            spaceBetween: 20,
                            slidesPerView: 1,
                        }
                    }}
                        modules={[Pagination, A11y, Autoplay]}
                        spaceBetween={20}
                        slidesPerView={5}
                        pagination={{ clickable: true }}
                        loop={true}
                        autoplay={false}
                        centeredSlides={true}
                    >
                        <SwiperSlide>
                            <div className="experienceBox">
                                <div className="experienceBox-inner pb-30 d-flex">
                                    <h5 className="fs-32 fw-600 textBlack">Hands-On Workshops</h5>
                                    <img className="widthAuto nonActive" src="/assets/images/handOnWork.svg" alt="handOnWork" />
                                    <img className="widthAuto active" src="/assets/images/handOnWorkOrg.svg" alt="handOnWork" />
                                </div>
                                <p className="fs-16 fw-400 textBlack">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="experienceBox">
                                <div className="experienceBox-inner pb-30 d-flex">
                                    <h5 className="fs-32 fw-600 textBlack">Hands-On Workshops</h5>
                                    <img className="widthAuto nonActive" src="/assets/images/handOnWork.svg" alt="handOnWork" />
                                    <img className="widthAuto active" src="/assets/images/handOnWorkOrg.svg" alt="handOnWork" />
                                </div>
                                <p className="fs-16 fw-400 textBlack">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="experienceBox">
                                <div className="experienceBox-inner pb-30 d-flex">
                                    <h5 className="fs-32 fw-600 textBlack">Hands-On Workshops</h5>
                                    <img className="widthAuto nonActive" src="/assets/images/handOnWork.svg" alt="handOnWork" />
                                    <img className="widthAuto active" src="/assets/images/handOnWorkOrg.svg" alt="handOnWork" />
                                </div>
                                <p className="fs-16 fw-400 textBlack">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="experienceBox">
                                <div className="experienceBox-inner pb-30 d-flex">
                                    <h5 className="fs-32 fw-600 textBlack">Hands-On Workshops</h5>
                                    <img className="widthAuto nonActive" src="/assets/images/handOnWork.svg" alt="handOnWork" />
                                    <img className="widthAuto active" src="/assets/images/handOnWorkOrg.svg" alt="handOnWork" />
                                </div>
                                <p className="fs-16 fw-400 textBlack">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="experienceBox">
                                <div className="experienceBox-inner pb-30 d-flex">
                                    <h5 className="fs-32 fw-600 textBlack">Hands-On Workshops</h5>
                                    <img className="widthAuto nonActive" src="/assets/images/handOnWork.svg" alt="handOnWork" />
                                    <img className="widthAuto active" src="/assets/images/handOnWorkOrg.svg" alt="handOnWork" />
                                </div>
                                <p className="fs-16 fw-400 textBlack">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="experienceBox">
                                <div className="experienceBox-inner pb-30 d-flex">
                                    <h5 className="fs-32 fw-600 textBlack">Hands-On Workshops</h5>
                                    <img className="widthAuto nonActive" src="/assets/images/handOnWork.svg" alt="handOnWork" />
                                    <img className="widthAuto active" src="/assets/images/handOnWorkOrg.svg" alt="handOnWork" />
                                </div>
                                <p className="fs-16 fw-400 textBlack">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="experienceBox">
                                <div className="experienceBox-inner pb-30 d-flex">
                                    <h5 className="fs-32 fw-600 textBlack">Hands-On Workshops</h5>
                                    <img className="widthAuto nonActive" src="/assets/images/handOnWork.svg" alt="handOnWork" />
                                    <img className="widthAuto active" src="/assets/images/handOnWorkOrg.svg" alt="handOnWork" />
                                </div>
                                <p className="fs-16 fw-400 textBlack">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>

            <section className="rewardSec p-80-20">
                <div className="container">
                    <h2 class="secTitle textBlack">Rewards</h2>
                    <div className="reward-outer">
                        <div className="rewardInner ">
                            <h5 className="textBlack fw-700 priceHeading">INR 50,000</h5>
                            <div className="innnerContent">
                                <div className="innnerContentTop">
                                    <h6 className="fw-600">12th standard student </h6>
                                    <p className="fs-18 fw-400 ">75% tuition scholarship for B.des</p>
                                </div>
                                <div className="innnerContentbotm">
                                    <h6 className="fw-600">9th to 11th standard student</h6>
                                    <p className="fs-14 fw-400">100% scholarship for FLAME Summer
                                        Immersion Programme + Round trip flight tickets</p>
                                </div>
                            </div>
                            <h5 className="textBlack fw-700 priceTitle">2nd Prize</h5>
                        </div>

                        <div className="rewardInner rewardInnerCenter">
                            <h5 className="textBlack fw-700 priceHeading">INR 1,00,000</h5>
                            <div className="innnerContent">
                                <div className="innnerContentTop">
                                    <h6 className="fw-600">12th standard student </h6>
                                    <p className="fs-18 fw-400 ">75% tuition scholarship for B.des</p>
                                </div>
                                <div className="innnerContentbotm">
                                    <h6 className="fw-600">9th to 11th standard student</h6>
                                    <p className="fs-14 fw-400">100% scholarship for FLAME Summer
                                        Immersion Programme + Round trip flight tickets</p>
                                </div>
                            </div>
                            <h5 className="textBlack fw-700 priceTitle">First Prize</h5>
                        </div>

                        <div className="rewardInner">
                            <h5 className="textBlack fw-700 priceHeading">INR 25,000</h5>
                            <div className="innnerContent">
                                <div className="innnerContentTop">
                                    <h6 className="fw-600">12th standard student </h6>
                                    <p className="fs-18 fw-400 ">75% tuition scholarship for B.des</p>
                                </div>
                                <div className="innnerContentbotm">
                                    <h6 className="fw-600">9th to 11th standard student</h6>
                                    <p className="fs-14 fw-400">100% scholarship for FLAME Summer
                                        Immersion Programme + Round trip flight tickets</p>
                                </div>
                            </div>
                            <h5 className="textBlack fw-700 priceTitle">3rd Prize</h5>
                        </div>
                    </div>
                </div>
            </section>
            <section className="blackBarSec text-center">
                <div className="container">
                    <p className="fs-18 fw-400 textWhite">Top 100 students get 100% scholarship to their Summer Programmme on Data analytics, mentors from Google, and Meta</p>
                </div>
            </section>

            <section className="programsSec rewardSecondSec p-80-20">
                <div className="container">
                    <h2 className="fs-80 fw-600 textBlack">Rewards</h2>
                    <div className="program-outer">
                        <div className="program-innerLeft">
                            <img src="assets/images/venueSpotImg.png" alt="RewardsImg" />
                        </div>
                        <div className="program-innerRight">
                            <h3 className="fs-34 fw-600 pb-20 textBlack ">Summer Programmme on<br />Data analytics</h3>
                            <p className="fs-16 fw-400 textBlack pb-20">Top 100 students get 100% scholarship to their Summer Programmme on Data analytics, mentors from Google, and Meta</p>
                            <h4 className="fs-18 fw-400 textBlack"><span className="fw-600">Venue</span>House of Lalpur</h4>
                            <ul className="progarmDetailList">
                                <li>
                                    <h6 className="fs-18 fw-600 textBlack"><img className="widthAuto"
                                        src="assets/images/pillarPage/LocationBlack.svg" alt="Location" />
                                        Location<span className="fs-18 fw-400">Indore</span></h6>
                                </li>
                                <li>
                                    <h6 className="fs-18 fw-600 textBlack"><img className="widthAuto"
                                        src="assets/images/pillarPage/CertificationBlack.svg" alt="Certification" />
                                        Certification<span className="fs-18 fw-400">We Provide</span></h6>
                                </li>
                                <li>
                                    <h6 className="fs-18 fw-600 textBlack"><img className="widthAuto"
                                        src="assets/images/pillarPage/DurationBlack.svg" alt="Duration" />
                                        Duration<span className="fs-18 fw-400">2 days </span></h6>
                                </li>
                                <li>
                                    <h6 className="fs-18 fw-600 textBlack"><img className="widthAuto"
                                        src="assets/images/pillarPage/ReidentialBlack.svg" alt="Reidential" />
                                        Cohort Size<span className="fs-18 fw-400">25-30</span></h6>
                                </li>
                            </ul>                            

<Popup
    trigger={<button className="registerBtn registerChall">Register for the challange<img className="widthAuto"
    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></button>}
    modal
    nested
  >
    {close => (
      <div className="modal">
        <button className="close" onClick={close}>
          &times;
        </button>
        <section className="bgBlack">
            <div className="contactPopup bglightBlack">
                
                <div className="contactForm">
                            <form>
                                <label for="fname">Name</label>
                                <input type="text" id="fname" placeholder="Name" />

                                <label for="cars">You are inquiring as a</label>
                                <select name="inquiring" id="inquiring">
                                    <option value="inquiring">You are inquiring as a</option>
                                </select>


                                <label for="fname">School Name</label>
                                <input type="text" id="fname" placeholder="School Name (if you are a parent, kindly fill in your Ward's school name)" />

                                <div className="loaction">
                                    <label for="location">School Location</label>
                                    <input type="tel" id="school-lacation" placeholder="School Location" />

                                    <label for="contact">Contact number</label>
                                    <input type="tel" id="contact-number" placeholder="Contact number" />
                                </div>

                                <label for="email">Email</label>
                                <input type="email" id="email" placeholder="Email" />

                                <label for="message">Message</label>
                                <textarea type="textarea" placeholder="Add a message"></textarea>

                                <button type="submit" className="formSubmit">Submit</button>
                            </form>
                        </div>
            </div>
        </section>        
        <div className="actions">
          {/* <Popup
            trigger={<button className="button"> Trigger </button>}
            position="top center"
            nested
          >
            <span>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae
              magni omnis delectus nemo, maxime molestiae dolorem numquam
              mollitia, voluptate ea, accusamus excepturi deleniti ratione
              sapiente! Laudantium, aperiam doloribus. Odit, aut.
            </span>
        

          </Popup>           */}
        </div>
      </div>
    )}
  </Popup>
                            {/* <a href="#" className="readMoreBtn">Read More</a> */}
                        </div>
                    </div>
                </div>
            </section> 

            <section className="partnerSec communityPartnerSec">
                <div className="container-full">
                    <h2 className="secTitle textBlack">Mentors from</h2>

                    <Swiper breakpoints={{
                        1440: {
                            spaceBetween: 25,
                            slidesPerView: 9,
                        },
                        768: {
                            spaceBetween: 20,
                            slidesPerView: 7,

                        },
                        200: {
                            spaceBetween: 20,
                            slidesPerView: 3,
                        }
                    }}
                        modules={[Pagination, A11y, Autoplay]}
                        spaceBetween={25}
                        slidesPerView={9}
                        pagination={{ clickable: true }}
                        loop={true}
                        autoplay={false}
                        centeredSlides={true}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                    >
                        <SwiperSlide>
                            <div className="partnerLogo">
                                <img src="/assets/images/partner2.png" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="partnerLogo">
                                <img src="/assets/images/partner4.png" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="partnerLogo">
                                <img src="/assets/images/partner3.png" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="partnerLogo">
                                <img src="/assets/images/partner2.png" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="partnerLogo">
                                <img src="/assets/images/partner1.png" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="partnerLogo">
                                <img src="/assets/images/partner5.png" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="partnerLogo">
                                <img src="/assets/images/partner3.png" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="partnerLogo">
                                <img src="/assets/images/partner4.png" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="partnerLogo">
                                <img src="/assets/images/partner1.png" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="partnerLogo">
                                <img src="/assets/images/partner2.png" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="partnerLogo">
                                <img src="/assets/images/partner3.png" />
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>

            <section className="callToAction pt-10 communityCallToAction">
                <div className="container  ">
                    <div className="  callToAction-inner">
                        <div className="row">
                            <div className="column callToActionLeft">
                                <h5>Ready to make a <br />difference?</h5>
                                <p>Register your school today to the Oxford MUN India 2024.</p>
                            </div>
                            <div className="column callToActionRight">
                                <p>Visit below link to secure your spot and embark on an unforgettable journey of discovery, diplomacy, and development.
                                </p>
                                <a href="https://bit.ly/CraftTheFutureChallengeBrochure" className="registerBtn" target="_blank">  Download Brochure </a>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section className="faqSec communityFaqSec ">

                <div className="container row faqInner">
                    <div className="faqInnerLeft">
                        <div className="faqInnerLeft-inner">
                            <img src="/assets/images/MU20-White-logo.png" alt="home-img" />
                            <p className="fs-18">Make a Difference with Us: Register Your School for OxfordMUN India 2024 Today!</p>
                            <p className="fs-18">Are you ready to embark on an extraordinary journey of discovery, diplomacy, and development? OxfordMUN India 2024 is inviting schools from across the globe to register and secure their spot in one of the most prestigious Model United Nations conferences. This is a unique opportunity for students to enhance their understanding of global issues, develop critical thinking skills, and engage in meaningful debates on world affairs.</p>
                            <a href="https://bit.ly/OxfordMUNRegistration" className="registerBtn " target="_blank"> Register Now </a>
                            <p className="fs-18 p-0">Still Have a question?  No worries.</p>

                            <div className="contactDetail">
                                <a href="mailto:madhur.oza@muniversiti.org " ><img src="/assets/images/email.svg" alt="home-img" />madhur.oza@muniversiti.org </a>
                                <a href="tel:7987519367" ><img src="/assets/images/whatsapp.svg" alt="home-img" />7987519367</a>
                            </div>
                            <div className="socialLinks">

                                <a href=" https://www.instagram.com/mu20.world?igsh=MThkY3NkeGJ3bHNtaA==" target="_blank">
                                    <img src="/assets/images/insta.svg" alt="home-img" /></a>
                                <a href="https://www.linkedin.com/company/mu20/" target="_blank">
                                    <img src="/assets/images/linkdine.svg" alt="home-img" /></a>
                            </div>
                        </div>
                    </div>
                    <div className="faqInnerRight">
                        <h2 className="secTitle textBlack">FAQs</h2>
                        <div class="faqBlock">
                            <Accordion allowZeroExpanded>
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            <h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                                Where is the venue for OxfordMUN India?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p className="faqContent">FLAME University Pune</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                            What are the dates for OxfordMUN India?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>9th-11th August</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                            How can I register for OxfordMUN India?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>Visit “Link” to secure your spot and embark on an unforgettable journey of Discovery, Diplomacy and Development.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                            What are the fees for participating in OxfordMUN India?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>- School Registration Fee: INR 5,000 (Includes one teacher representative without any additional charge)</p>
                                        <p> - Delegate Fee for MU20 Member Schools: INR 25,000 per delegate</p>
                                        <p> - Delegate Fee for Non-Member Schools: INR 30,000 per delegate</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                            Who can participate in OxfordMUN India?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>Students from Grade 9th to 12th</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                            Will accommodation be provided for participants?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>Yes, the participants and their teacher coordinators would be provided with accommodation at FLAMES University Pune.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                            How can I get to the venue from the airport/train station?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>Convenient pickup and drop-off services from local stations and airports would be provided to participants.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                            Who can I contact for more information or assistance?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>Madhur Oza <a href="tel:+91-7987519367"> +91-7987519367 </a>&nbsp;&nbsp;<a href="mailTo:madhur.oza@muniversiti.org">madhur.oza@muniversiti.org</a> </p>
                                        <p>Ashwin Samuel <a href="tel:+91-9424536621"> +91-9424536621 </a>&nbsp;&nbsp;<a href="mailTo:connect@mu20.co">connect@mu20.co</a>
                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                            </Accordion>

                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default Community

