import React, { useState } from 'react';
import './Home.css';
import Header from './Header/Header';
import Footer from './Footer/Footer';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

// import PureModal from 'react-pure-modal';
// import 'react-pure-modal/dist/react-pure-modal.min.css';

// ----------------Swiper slider import------------------
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import "swiper/css/effect-fade";
// import Swiper from 'swiper/bundle';
// import 'swiper/css/bundle';

// ----------------Gallery slider import------------------
import ImageGallery from 'react-image-gallery';
import { ModalProvider } from 'styled-react-modal';
import { Button, Modal } from 'antd';
import RegisterModal from '../Popup/RegisterModal';

function HomeNew() {
  const [isMuted, setIsMuted] = useState(true);
  const [isActive, setActive] = useState("false");

  const ToggleClass = () => {
    setActive(!isActive);
  };

  return (
    <>
      <Header />

      <section className="oxfordBanner">
        <div>
          <video className="sliderBgImage" autoPlay loop playsinline width="100%" muted={isMuted ? true : false}>
            {/* <source src="assets/videos/OxfordVideo-Com.mp4" type="video/mp4" /> */}
            <source src="https://res.cloudinary.com/dpj2oayjr/video/upload/v1711693095/MU_New_Site/iv3tdgjgarbau4qlm7h4.mp4" type="video/mp4" />
          </video>
        </div>
        <div className="banner-content">
          {/* <h1>OxfordMUN India</h1> */}
          <div className={isActive ? "mute" : "unmute"}>
            <a onClick={() => { setIsMuted(!isMuted); ToggleClass(); }} className="muteBtn widthAuto" id="btnUnMute"><img className="unmuteIcon" src="/assets/images/unmute.svg" alt="unMuteIcon" />
              <img className="muteIcon" src="/assets/images/mute.svg" alt="MuteIcon" /></a>
          </div>
        </div>
      </section >

      <section className="p-80-20 bgBlack ourPillarsSec">
        <div className="container-full">
          <div className="topHeading">
            <h6 className="fs-22 fw-600 textOrg pb-10 textUpper">The Cornerstones</h6>
            <h2 className="fs-80 fw-600 textWhite">Our Pillars</h2>
          </div>
          <div className="ourPillars-outer">

            <div className="pillars">
              <video className="bgPillarsImg" width="400" muted>
                <source src="assets/videos/video1.mov" type="video/mp4" />
                <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
              </video>
              <div className="titleTop">
                <h4 className="fs-16 fw-600 bgOrg textWhite">Academy</h4>
                <h3 className="fs-34 fw-700 textWhite">Embrace Challenge</h3>
              </div>
              <div className="content">
                <p className="fs-22 fw-500 textWhite">Lorem Ipsum is simply dummy text of the printing and
                  typesetting
                </p>
                <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
              </div>
            </div>

            <div className="pillars">
              <video className="bgPillarsImg" width="400" muted>
                <source src="assets/videos/video3.mov" type="video/mp4" />
                <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
              </video>
              <div className="titleTop">
                <h4 className="fs-16 fw-600 bgYellow textWhite">Academy</h4>
                <h3 className="fs-34 fw-700 textWhite">Embrace Challenge</h3>
              </div>
              <div className="content">
                <p className="fs-22 fw-500 textWhite">Lorem Ipsum is simply dummy text of the printing and
                  typesetting
                </p>
                <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
              </div>
            </div>

            <div className="pillars">
              <video className="bgPillarsImg" width="400" muted>
                <source src="assets/videos/video2.mov" type="video/mp4" />
                <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
              </video>
              <div className="titleTop">
                <h4 className="fs-16 fw-600 bgSkyBlue textWhite">Academy</h4>
                <h3 className="fs-34 fw-700 textWhite">Embrace Challenge</h3>
              </div>
              <div className="content">
                <p className="fs-22 fw-500 textWhite">Lorem Ipsum is simply dummy text of the printing and
                  typesetting
                </p>
                <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
              </div>
            </div>

            <div className="pillars">
              <video className="bgPillarsImg" width="400" muted>
                <source src="assets/videos/video1.mov" type="video/mp4" />
                <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
              </video>
              <div className="titleTop">
                <h4 className="fs-16 fw-600 bgDarkRed textWhite">Academy</h4>
                <h3 className="fs-34 fw-700 textWhite">Embrace Challenge</h3>
              </div>
              <div className="content">
                <p className="fs-22 fw-500 textWhite">Lorem Ipsum is simply dummy text of the printing and
                  typesetting
                </p>
                <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="p-80-20 bgBlack feacProgramSec">
        <div className="container-full">
          <div className="topHeading">
            <h6 className="fs-22 fw-600 textOrg pb-10 textUpper">Explore</h6>
            <h2 className="fs-80 fw-600 textWhite">Featured Program</h2>
          </div>
          <Swiper
            breakpoints={{
              1440: {
                spaceBetween: 30,
                slidesPerView: 4
              },
              768: {
                spaceBetween: 30,
                slidesPerView: 3,
              },
              200: {
                spaceBetween: 20,
                slidesPerView: 1,
              }
            }}
            // install Swiper modules
            modules={[Navigation, Autoplay, A11y]}
            spaceBetween={30}
            slidesPerView={3}
            navigation={false}
            loop={true}
            autoplay={{
              delay: 2000,
            }}
            freeMode={true} 
            centeredSlides={false}

          >
            <SwiperSlide>
              <div className="sliderOne">
                <video className="sliderBgImage" width="400" muted>
                  <source src="assets/videos/video1.mov" type="video/mp4" />
                  <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                </video>
                <div className="programContent">
                  <div className="programContent-inner">
                    <h6 className="textUpper lineHeight1">Find Your Voice</h6>
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">Leadership and Adventure Bootcamp
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">Lorem Ipsum is simply dummy text of the printing
                      and typesetting
                    </p>
                  </div>
                  <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sliderOne">
                <video className="sliderBgImage" width="400" muted>
                  <source src="assets/videos/video2.mov" type="video/mp4" />
                  <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                </video>
                <div className="programContent">
                  <div className="programContent-inner">
                    <h6 className="textUpper lineHeight1">Find Your Voice</h6>
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">Leadership and Adventure Bootcamp
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">Lorem Ipsum is simply dummy text of the printing
                      and typesetting
                    </p>
                  </div>
                  <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sliderOne">
                <video className="sliderBgImage" width="400" muted>
                  <source src="assets/videos/video3.mov" type="video/mp4" />
                  <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                </video>
                <div className="programContent">
                  <div className="programContent-inner">
                    <h6 className="textUpper lineHeight1">Find Your Voice</h6>
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">Leadership and Adventure Bootcamp
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">Lorem Ipsum is simply dummy text of the printing
                      and typesetting
                    </p>
                  </div>
                  <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sliderOne">
                <video className="sliderBgImage" width="400" muted>
                  <source src="assets/videos/video1.mov" type="video/mp4" />
                  <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                </video>
                <div className="programContent">
                  <div className="programContent-inner">
                    <h6 className="textUpper lineHeight1">Find Your Voice</h6>
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">Leadership and Adventure Bootcamp
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">Lorem Ipsum is simply dummy text of the printing
                      and typesetting
                    </p>
                  </div>
                  <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sliderOne">
                <video className="sliderBgImage" width="400" muted>
                  <source src="assets/videos/video1.mov" type="video/mp4" />
                  <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                </video>
                <div className="programContent">
                  <div className="programContent-inner">
                    <h6 className="textUpper lineHeight1">Find Your Voice</h6>
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">Leadership and Adventure Bootcamp
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">Lorem Ipsum is simply dummy text of the printing
                      and typesetting
                    </p>
                  </div>
                  <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>

          <Swiper className="feacProgram-outerCenter"
            breakpoints={{
              1440: {
                spaceBetween: 30,
                slidesPerView: 4
              },
              768: {
                spaceBetween: 30,
                slidesPerView: 3,
              },
              200: {
                spaceBetween: 20,
                slidesPerView: 1,
              }
            }}
            // install Swiper modules
            modules={[Navigation, Autoplay, A11y]}
            spaceBetween={30}
            slidesPerView={3}
            navigation={false}
            loop={true}
            freeMode={true}
            autoplay={{
              delay: 1500
            }}
            centeredSlides={true}
          >
            <SwiperSlide>
              <div className="sliderOne">
                <video className="sliderBgImage" width="400" muted>
                  <source src="assets/videos/video3.mov" type="video/mp4" />
                  <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                </video>
                <div className="programContent">
                  <div className="programContent-inner">
                    <h6 className="textUpper lineHeight1">Find Your Voice</h6>
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">Leadership and Adventure Bootcamp
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">Lorem Ipsum is simply dummy text of the printing
                      and typesetting
                    </p>
                  </div>
                  <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sliderOne">
                <video className="sliderBgImage" width="400" muted>
                  <source src="assets/videos/video2.mov" type="video/mp4" />
                  <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                </video>
                <div className="programContent">
                  <div className="programContent-inner">
                    <h6 className="textUpper lineHeight1">Find Your Voice</h6>
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">Leadership and Adventure Bootcamp
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">Lorem Ipsum is simply dummy text of the printing
                      and typesetting
                    </p>
                  </div>
                  <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sliderOne">
                <video className="sliderBgImage" width="400" muted>
                  <source src="assets/videos/video1.mov" type="video/mp4" />
                  <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                </video>
                <div className="programContent">
                  <div className="programContent-inner">
                    <h6 className="textUpper lineHeight1">Find Your Voice</h6>
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">Leadership and Adventure Bootcamp
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">Lorem Ipsum is simply dummy text of the printing
                      and typesetting
                    </p>
                  </div>
                  <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sliderOne">
                <video className="sliderBgImage" width="400" muted>
                  <source src="assets/videos/video2.mov" type="video/mp4" />
                  <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                </video>
                <div className="programContent">
                  <div className="programContent-inner">
                    <h6 className="textUpper lineHeight1">Find Your Voice</h6>
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">Leadership and Adventure Bootcamp
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">Lorem Ipsum is simply dummy text of the printing
                      and typesetting
                    </p>
                  </div>
                  <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sliderOne">
                <video className="sliderBgImage" width="400" muted>
                  <source src="assets/videos/video1.mov" type="video/mp4" />
                  <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                </video>
                <div className="programContent">
                  <div className="programContent-inner">
                    <h6 className="textUpper lineHeight1">Find Your Voice</h6>
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">Leadership and Adventure Bootcamp
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">Lorem Ipsum is simply dummy text of the printing
                      and typesetting
                    </p>
                  </div>
                  <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>

          <Swiper
            breakpoints={{
              1440: {
                spaceBetween: 30,
                slidesPerView: 4
              },
              768: {
                spaceBetween: 30,
                slidesPerView: 3,
              },
              200: {
                spaceBetween: 20,
                slidesPerView: 1,
              }
            }}
            // install Swiper modules
            modules={[Navigation, Autoplay, A11y]}
            spaceBetween={30}
            slidesPerView={3}
            navigation={false}
            loop={true}
            freeMode={true}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false
            }}
            centeredSlides={false}
          >
            <SwiperSlide>
              <div className="sliderOne">
                <video className="sliderBgImage" width="400" muted>
                  <source src="assets/videos/video2.mov" type="video/mp4" />
                  <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                </video>
                <div className="programContent">
                  <div className="programContent-inner">
                    <h6 className="textUpper lineHeight1">Find Your Voice</h6>
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">Leadership and Adventure Bootcamp
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">Lorem Ipsum is simply dummy text of the printing
                      and typesetting
                    </p>
                  </div>
                  <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sliderOne">
                <video className="sliderBgImage" width="400" muted>
                  <source src="assets/videos/video3.mov" type="video/mp4" />
                  <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                </video>
                <div className="programContent">
                  <div className="programContent-inner">
                    <h6 className="textUpper lineHeight1">Find Your Voice</h6>
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">Leadership and Adventure Bootcamp
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">Lorem Ipsum is simply dummy text of the printing
                      and typesetting
                    </p>
                  </div>
                  <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sliderOne">
                <video className="sliderBgImage" width="400" muted>
                  <source src="assets/videos/video1.mov" type="video/mp4" />
                  <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                </video>
                <div className="programContent">
                  <div className="programContent-inner">
                    <h6 className="textUpper lineHeight1">Find Your Voice</h6>
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">Leadership and Adventure Bootcamp
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">Lorem Ipsum is simply dummy text of the printing
                      and typesetting
                    </p>
                  </div>
                  <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sliderOne">

                <video className="sliderBgImage" width="400" muted >
                  <source src="assets/videos/video2.mov" type="video/mp4" />
                </video>
                <div className="programContent">
                  <div className="programContent-inner">
                    <h6 className="textUpper lineHeight1">Find Your Voice</h6>
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">Leadership and Adventure Bootcamp
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">Lorem Ipsum is simply dummy text of the printing
                      and typesetting
                    </p>
                  </div>
                  <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sliderOne">
                <video className="sliderBgImage" width="400" muted>
                  <source src="assets/videos/video1.mov" type="video/mp4" />
                  <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                </video>
                <div className="programContent">
                  <div className="programContent-inner">
                    <h6 className="textUpper lineHeight1">Find Your Voice</h6>
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">Leadership and Adventure Bootcamp
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">Lorem Ipsum is simply dummy text of the printing
                      and typesetting
                    </p>
                  </div>
                  <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>

        </div>
      </section >

      <section className="p-80-20 bglightBlack networkSchoolSec">
        <div className="container">
          <div className="topHeading">
            <h6 className="fs-22 fw-600 textOrg pb-10 textUpper">Our Partner</h6>
            <h2 className="fs-80 fw-600 textWhite">Network Schools</h2>
            <p className="subContent">In recognition of the challenges in creating abundant opportunities at scale,
              our vision is to curate these transformative experiences for a select network of schools that
              share our ideology for creating exceptional opportunities for high school students.</p>
          </div>
          <div className="networkSchoolSlider-outer">
            <Swiper
              breakpoints={{
                1440: {
                  spaceBetween: 30,
                  slidesPerView: 4
                },
                768: {
                  spaceBetween: 30,
                  slidesPerView: 3,
                },
                200: {
                  spaceBetween: 20,
                  slidesPerView: 1,
                }
              }}
              // install Swiper modules
              modules={[Navigation, Pagination, Autoplay, A11y]}
              spaceBetween={30}
              slidesPerView={4}
              navigation={false}
              loop={true}
              autoplay={false}
              centeredSlides={false}
              pagination={{ clickable: true }}
            >
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/homeImage/networkSch1.svg" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/homeImage/networkSch2.svg" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/homeImage/networkSch3.svg" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/homeImage/networkSch4.svg" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/homeImage/networkSch4.svg" alt="networkSch" />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>

      <section className="p-80-20 bgBlack  pastProgVideoSec">
        <div className="container-full">
          <div className="topHeading">
            <h6 className="fs-22 fw-600 textOrg pb-10 textUpper">Our Video</h6>
            <h2 className="fs-80 fw-600 textWhite">Past Program Videos</h2>
          </div>
        </div>
        <Swiper breakpoints={{
          1440: {
            spaceBetween: 30,
            slidesPerView: 5,
          },
          768: {
            spaceBetween: 20,
            slidesPerView: 3,

          },
          200: {
            spaceBetween: 20,
            slidesPerView: 1,
          }
        }}
          modules={[Pagination, A11y, Autoplay]}
          spaceBetween={30}
          slidesPerView={5}
          pagination={{ clickable: true }}
          loop={true}
          autoplay={false}
          centeredSlides={true}
        >
          <SwiperSlide>
            <div className="pastProgVideos">
              <h4 className="fs-25 fw-600 textUpper textWhite pb-20">Building the world</h4>
              <video className="progVideos " muted>
                <source src="assets/videos/video1.mov" type="video/mp4" />
                <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
              </video>
              {/* <img src="assets/images/homeImage/pastProgActive.png" alt="networkSch" /> */}
              <div className="progVedioContent">
                <p className="fs-25 fw-400">In recognition of the challenges in creating abundant opportunities at
                  scale, our vision is to</p>
                <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="pastProgVideos">
              <h4 className="fs-25 fw-600 textUpper textWhite pb-20">Building the world</h4>
              <video className="progVideos " muted>
                <source src="assets/videos/video2.mov" type="video/mp4" />
                <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
              </video>
              {/* <img src="assets/images/homeImage/pastProgActive.png" alt="networkSch" /> */}
              <div className="progVedioContent">
                <p className="fs-25 fw-400">In recognition of the challenges in creating abundant opportunities at
                  scale, our vision is to</p>
                <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="pastProgVideos">
              <h4 className="fs-25 fw-600 textUpper textWhite pb-20">Building the world</h4>
              <video className="progVideos " muted>
                <source src="assets/videos/video3.mov" type="video/mp4" />
                <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
              </video>
              {/* <img src="assets/images/homeImage/pastProgActive.png" alt="networkSch" /> */}
              <div className="progVedioContent">
                <p className="fs-25 fw-400">In recognition of the challenges in creating abundant opportunities at
                  scale, our vision is to</p>
                <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="pastProgVideos ">
              <h4 className="fs-25 fw-600 textUpper textWhite pb-20">Building the world</h4>
              <video className="progVideos " muted>
                <source src="assets/videos/video1.mov" type="video/mp4" />
                <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
              </video>
              {/* <img src="assets/images/homeImage/pastProgActive.png" alt="networkSch" /> */}
              <div className="progVedioContent">
                <p className="fs-25 fw-400">In recognition of the challenges in creating abundant opportunities at
                  scale, our vision is to</p>
                <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="pastProgVideos  ">
              <h4 className="fs-25 fw-600 textUpper textWhite pb-20">Building the world</h4>
              <video className="progVideos " muted>
                <source src="assets/videos/video2.mov" type="video/mp4" />
                <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
              </video>
              {/* <img src="assets/images/homeImage/pastProgActive.png" alt="networkSch" /> */}
              <div className="progVedioContent">
                <p className="fs-25 fw-400">In recognition of the challenges in creating abundant opportunities at
                  scale, our vision is to</p>
                <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="pastProgVideos  ">
              <h4 className="fs-25 fw-600 textUpper textWhite pb-20">Building the world</h4>
              <video className="progVideos " muted>
                <source src="assets/videos/video3.mov" type="video/mp4" />
                <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
              </video>
              {/* <img src="assets/images/homeImage/pastProgActive.png" alt="networkSch" /> */}
              <div className="progVedioContent">
                <p className="fs-25 fw-400">In recognition of the challenges in creating abundant opportunities at
                  scale, our vision is to</p>
                <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="pastProgVideos  ">
              <h4 className="fs-25 fw-600 textUpper textWhite pb-20">Building the world</h4>
              <video className="progVideos " muted>
                <source src="assets/videos/video1.mov" type="video/mp4" />
                <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
              </video>
              {/* <img src="assets/images/homeImage/pastProgActive.png" alt="networkSch" /> */}
              <div className="progVedioContent">
                <p className="fs-25 fw-400">In recognition of the challenges in creating abundant opportunities at
                  scale, our vision is to</p>
                <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>

      </section>

      <section className="p-80-20 bglightBlack skillSec">
        <div className="topHeading">
          <h6 className="fs-22 fw-600 textOrg pb-10 textUpper">Why choose me</h6>
          <h2 className="fs-80 fw-600 textWhite pb-20">Skills You Learn</h2>
          <p className="subContent textWhite">Building the worlds best marketing Your trusted partner for strategy,
            design, and dev</p>
        </div>
        <div className="skills-outer">
          <div className="skills">
            <img src="assets/images/homeImage/skillImg.png" alt="skillImg" />
            <h3 className="fs-25 fw-600 pb-20">Building the worlds best marketing Your trusted</h3>
            <ul className="skillList">
              <li className="fs-16 fw-400">Lorem Ipsum is simply dummy text of the printing and typesetting
                industry</li>
              <li className="fs-16 fw-400">Lorem Ipsum is simply dummy text of the printing and typesetting
                industry</li>
              <li className="fs-16 fw-400">Lorem Ipsum is simply dummy text of the printing and typesetting
                industry</li>
            </ul>
          </div>
          <div className="skills">
            <img src="assets/images/homeImage/skillImg.png" alt="skillImg" />
            <h3 className="fs-25 fw-600 pb-20">Building the worlds best marketing Your trusted</h3>
            <ul className="skillList">
              <li className="fs-16 fw-400">Lorem Ipsum is simply dummy text of the printing and typesetting
                industry</li>
              <li className="fs-16 fw-400">Lorem Ipsum is simply dummy text of the printing and typesetting
                industry</li>
              <li className="fs-16 fw-400">Lorem Ipsum is simply dummy text of the printing and typesetting
                industry</li>
            </ul>
          </div>
          <div className="skills">
            <img src="assets/images/homeImage/skillImg.png" alt="skillImg" />
            <h3 className="fs-25 fw-600 pb-20">Building the worlds best marketing Your trusted</h3>
            <ul className="skillList">
              <li className="fs-16 fw-400">Lorem Ipsum is simply dummy text of the printing and typesetting
                industry</li>
              <li className="fs-16 fw-400">Lorem Ipsum is simply dummy text of the printing and typesetting
                industry</li>
              <li className="fs-16 fw-400">Lorem Ipsum is simply dummy text of the printing and typesetting
                industry</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="p-80-20  bgBlack intreactionSec">
        <Swiper modules={[Autoplay, Navigation, A11y, EffectFade]}
          spaceBetween={0}
          slidesPerView={1}
          loop={true}
          autoplay={false}
          centeredSlides={true}
          effect="fade"
          navigation={true}
        >
          <SwiperSlide>
            <div className="intreactionVideo">
              <video muted>
                <source src="assets/videos/video1.mov" type="video/mp4" />
                <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
              </video>
              {/* <img src="assets/images/reviewImg.png" alt="home-img" /> */}
              <div className="intreactionInner">
                <h2 className="fs-80 fw-700 textWhite">250K+ </h2>
                <p className="fs-34 fw-700 textWhite">Student Interactions</p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="intreactionVideo">
              <video muted>
                <source src="assets/videos/video2.mov" type="video/mp4" />
                <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
              </video>
              {/* <img src="assets/images/reviewImg.png" alt="home-img" /> */}
              <div className="intreactionInner">
                <h2 className="fs-80 fw-700 textWhite">250K+ </h2>
                <p className="fs-34 fw-700 textWhite">Student Interactions</p>
              </div>
            </div>
          </SwiperSlide> 
        </Swiper>
      </section>


      {/* <section className="p-80-20  bgBlack intreactionSec">
        <img src="assets/images/reviewImg.png" alt="home-img" />
        <div className="intreactionInner">
          <h2 className="fs-80 fw-700 textWhite">250K+ </h2>
          <p className="fs-34 fw-700 textWhite">Student Interactions</p>
        </div>
      </section> */}


      <section className="bgBlack testimonialSec testimonialHomeSec">
        <div className="container-full">
          <div className="topHeading">
            <h6 className="fs-22 fw-600 textOrg pb-10 textUpper">clients</h6>
            <h2 className="fs-80 fw-600 textWhite">Testimonials</h2>
          </div>

          <Swiper breakpoints={{
            1440: {
              spaceBetween: 50,
              slidesPerView: 4,
              centeredSlides: false,
            },
            768: {
              spaceBetween: 30,
              slidesPerView: 3,

            },
            200: {
              spaceBetween: 20,
              slidesPerView: 1,
            }
          }}
            modules={[Autoplay, A11y]}
            spaceBetween={50}
            slidesPerView={5}
            loop={true}
            autoplay={false}
            centeredSlides={true}
          >
            <SwiperSlide>
              <div className="testimonialImg">
                <video className="testimonialVideo " controls muted>
                  <source src="assets/videos/video1.mov" type="video/mp4" />
                  <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                </video>
                {/* <img src="assets/images/testimonial1.png" alt='home-img' /> */}
                <div className="textWrapper">
                  <img src="/assets/images/videoSec.svg" alt='home-img' />

                  <h6 className="titleWatch">Watch Story</h6>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="testimonialImg">
                <video className="testimonialVideo " controls muted>
                  <source src="assets/videos/video2.mov" type="video/mp4" />
                  <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                </video>
                {/* <img src="assets/images/testimonial2.png" alt='home-img' /> */}
                <div className="textWrapper">
                  <img src="/assets/images/videoSec.svg" alt='home-img' />
                  <h6 className="titleWatch">Watch Story</h6>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="testimonialImg">
                <video className="testimonialVideo " controls muted>
                  <source src="assets/videos/video3.mov" type="video/mp4" />
                  <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                </video>
                {/* <img src="assets/images/testimonial3.png" alt='home-img' /> */}
                <div className="textWrapper">
                  <img src="/assets/images/videoSec.svg" alt='home-img' />
                  <h6 className="titleWatch">Watch Story</h6>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="testimonialImg">
                <video className="testimonialVideo " controls muted>
                  <source src="assets/videos/video1.mov" type="video/mp4" />
                  <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                </video>
                {/* <img src="assets/images/testimonial1.png" alt='home-img' /> */}
                <div className="textWrapper">
                  <img src="/assets/images/videoSec.svg" alt='home-img' />
                  <h6 className="titleWatch">Watch Story</h6>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="testimonialImg">
                <video className="testimonialVideo " controls muted>
                  <source src="assets/videos/video2.mov" type="video/mp4" />
                  <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                </video>
                {/* <img src="assets/images/testimonial2.png" alt='home-img' /> */}
                <div className="textWrapper">
                  <img src="/assets/images/videoSec.svg" alt='home-img' />
                  <h6 className="titleWatch">Watch Story</h6>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>

      <section className="bgBlack partnerSec partnerHomeSec pt-0">
        <div className="container-full">
          <div className="topHeading">
            <h6 className="fs-22 fw-600 textOrg pb-10 textUpper">community</h6>
            <h2 className="fs-80 fw-600 textWhite">Partners</h2>
          </div>

          <Swiper breakpoints={{
            1440: {
              spaceBetween: 25,
              slidesPerView: 9,
            },
            768: {
              spaceBetween: 20,
              slidesPerView: 7,

            },
            200: {
              spaceBetween: 20,
              slidesPerView: 3,
            }
          }}
            modules={[Pagination, A11y, Autoplay]}
            spaceBetween={25}
            slidesPerView={9}
            pagination={{ clickable: true }}
            loop={true}
            autoplay={false}
            centeredSlides={true}
          >
            <SwiperSlide>
              <div className="partnerLogo">
                <img src="assets/images/homeImage/partnerWhite1.svg" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="partnerLogo">
                <img src="assets/images/homeImage/partnerWhite2.svg" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="partnerLogo">
                <img src="assets/images/homeImage/partnerWhite3.svg" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="partnerLogo">
                <img src="assets/images/homeImage/partnerWhite4.svg" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="partnerLogo">
                <img src="assets/images/homeImage/partnerWhite5.svg" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="partnerLogo">
                <img src="assets/images/homeImage/partnerWhite1.svg" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="partnerLogo">
                <img src="assets/images/homeImage/partnerWhite2.svg" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="partnerLogo">
                <img src="assets/images/homeImage/partnerWhite1.svg" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="partnerLogo">
                <img src="assets/images/homeImage/partnerWhite1.svg" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="partnerLogo">
                <img src="assets/images/homeImage/partnerWhite1.svg" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="partnerLogo">
                <img src="assets/images/homeImage/partnerWhite1.svg" />
              </div>
            </SwiperSlide>
          </Swiper>


          {/* <div className="partnerLogoGrid">
            <div className="partnerLogo">
              <img src="assets/images/homeImage/partnerWhite1.svg" alt="parterLogo" />
            </div>
            <div className="partnerLogo">
              <img src="assets/images/homeImage/partnerWhite2.svg" alt="parterLogo" />
            </div>
            <div className="partnerLogo">
              <img src="assets/images/homeImage/partnerWhite3.svg" alt="parterLogo" />
            </div>
            <div className="partnerLogo">
              <img src="assets/images/homeImage/partnerWhite4.svg" alt="parterLogo" />
            </div>
            <div className="partnerLogo">
              <img src="assets/images/homeImage/partnerWhite5.svg" alt="parterLogo" />
            </div>
            <div className="partnerLogo">
              <img src="assets/images/homeImage/partnerWhite1.svg" alt="parterLogo" />
            </div>
            <div className="partnerLogo">
              <img src="assets/images/homeImage/partnerWhite2.svg" alt="parterLogo" />
            </div>
            <div className="partnerLogo">
              <img src="assets/images/homeImage/partnerWhite3.svg" alt="parterLogo" />
            </div>
            <div className="partnerLogo">
              <img src="assets/images/homeImage/partnerWhite4.svg" alt="parterLogo" />
            </div>
          </div> */}

        </div>
      </section>

      <Footer />


    </>
  )
}

export default HomeNew