import logo from './logo.svg';
import './App.css';
import HomeNew from './components/Home/HomeNew';
import { Routes , Route } from 'react-router-dom';
import OxfordMun from './components/Home/OxfordMun';
import Community from './components/Home/Community';
import Flagship from './components/Home/Flagship';
import Residential from './components/Home/Residential';
import Bootcamp from './components/Home/Bootcamp';
import NetworkSchool from './components/Home/NetworkSchool';
import Pillar from './components/Home/Pillar';


function App() {
  return (
    <div className="App">
      <Routes >
        <Route path='/' element={<OxfordMun />} />
        <Route path='/community' element={<Community />} />
        <Route path='/flagship' element={<Flagship />} />
        <Route path='/residential' element={<Residential />} />
        <Route path='/bootcamp' element={<Bootcamp />} />
        <Route path='/home-new' element={<HomeNew />} />
        <Route path='networkSchool' element={<NetworkSchool />} />
        <Route path='/pillar' element={<Pillar />} />
      </Routes>
    </div>
  );
}

export default App;
