import React, { useState } from 'react';
import './Home.css';
import Header from './Header/Header';
import Footer from './Footer/Footer';



import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';


// ----------------Swiper slider import------------------
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import "swiper/css/effect-fade";
// ----------------Gallery slider import------------------
import ImageGallery from 'react-image-gallery';
import { ModalProvider } from 'styled-react-modal';
import { Button, Modal } from 'antd';
import RegisterModal from '../Popup/RegisterModal';

//--------------------Faq Accodian------------------  

// ----------------Gallery slider start------------------
const images = [
    {
        original: '/assets/images/Flames1.png',
        thumbnail: '/assets/images/Flames1.png',
    },
    {
        original: '/assets/images/Flames2.png',
        thumbnail: '/assets/images/Flames2.png',
    },
    {
        original: '/assets/images/Flames3.png',
        thumbnail: '/assets/images/Flames3.png',
    },
];
// ----------------Gallery slider end------------------
function Bootcamp() {

    return (
        <>

            <Header />
            {/* <!-- banner section --> */}
            <section className="oxfordBanner">
                <div>
                    <video className="sliderBgImage" autoPlay loop muted controls width="100%">
                        <source src="assets/videos/OxfordVideo-Com.mp4" type="video/mp4" />
                        <source src="assets/imges/fecProgram3.png" type="video/ogg" />
                    </video>
                </div>
                <div className="banner-content">
                    <h1>OxfordMUN India</h1>
                    <a className="muteBtn widthAuto" id="btnUnMute">Unmute <img src="/assets/images/unmute.svg" alt="unMuteIcon" /></a>
                    <a className="muteBtn widthAuto" id="btnMute">Mute <img src="/assets/images/mute.svg" alt="muteIcon" /></a>
                </div>
            </section >
            {/* <!-- introduction --> */}
            < section className="intro-section" >
                <div className="container">
                    <div className="introduction">
                        <h2 className="secTitle">Introduction</h2>
                        <p>The OxfordMUN, a beacon of diplomatic simulations worldwide, marks its groundbreaking entry in India at FLAME University, Pune, from 9th to 11th August 2024. Orchestrated by the MU20 School of Opportunity, this event is a fusion of Oxford's prestigious academic standards with the vibrant cultural diversity of India, offering an unparalleled experience.</p>
                    </div>
                </div>
            </section >
            {/* <!-- Why OxfordMUN India ? --> */}
            < section className="whyOxfordSec" >
                <div className="container">
                    <div className="oxford-mun">
                        <h2 className="secTitle">Why OxfordMUN India ?</h2>
                    </div>
                    <div className="inner-box">
                        <div className="row">
                            <div className="column">
                                <div className="oxford-box">
                                    <h3>Legacy Meets Opportunity</h3>
                                    <p>For the first time in India, high school students (Classes 9th to 12th) have the chance to engage in a globally recognized platform for debate, diplomacy, and leadership.</p>
                                </div>
                                <div className="oxford-box">
                                    <h3>Empowerment Through Engagement</h3>
                                    <p>With committee sizes limited to 70 delegates, OxfordMUN India ensures meaningful interaction, fostering a conducive environment for every participant to contribute effectively.</p>
                                </div>
                            </div>
                            <div className="column">
                                <div className="oxford-box">
                                    <h3>Comprehensive Learning Experience</h3>
                                    <p>Beyond debates, the event features training sessions, Power Talks, and special events like the Masquerade Ball and Global Village Gala to enrich participants' journey.</p>
                                </div>
                                <div className="oxford-box">
                                    <h3>Recognition for the best delegates</h3>
                                    <p>The Best Delegates will be awarded a 100% scholarship on the registration fee and accommodation to participate in OxfordMUN at Oxford, offering them international exposure and the chance to further their diplomatic engagement on a global stage.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            {/* <!-- Conference Details --> */}
            < section className="conference" >
                <div className="container">
                    <div className="confere-detail">
                        <h2 className="secTitle">Conference Details</h2>

                        <div className="row">
                            <div className="column">
                                <div className="confere-box">
                                    <h3>Who</h3>
                                    <p>Students studying in classes </p><span className="bgColorText">9th to 12th can
                                        register</span>

                                </div>
                                <div className="confere-box m-0">
                                    <h3>When and Where</h3>
                                    <span className="bgColorText">09th August 2024 - 10th August 2024 -</span>

                                    <p><span className="bgColorText"> 11th August 2024</span> at FLAME University, Pune
                                    </p>
                                </div>
                            </div>
                            <div className="column confere-reg">
                                <div className="confere-regInner">
                                    <h3>Registration</h3>
                                    <p>To express your interest in joining, schools are
                                        requested to register by <span>30th April 2024.</span> Please note that the priority
                                        registrations for students will end on <span>29th June</span> , and final registration
                                        deadlines will be <span>16th July 2024.</span>
                                    </p>
                                    <a href="https://bit.ly/OxfordMUNRegistration" className="registerBtn" target="_blank"> Register Now </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <section className="whyOxfordSec eventSec">
                <div className="container">
                    <div className="oxford-mun">
                        <h2 className="secTitle">Events</h2>
                    </div>

                    <Swiper
                        breakpoints={{
                            1440: {
                                spaceBetween: 50,
                                slidesPerView: 3
                            },
                            768: {
                                spaceBetween: 40,
                                slidesPerView: 2,
                                centeredSlides: false,
                            },
                            200: {
                                spaceBetween: 20,
                                slidesPerView: 1,
                            }
                        }}
                        // install Swiper modules
                        modules={[Navigation, Autoplay, A11y]}
                        spaceBetween={60}
                        slidesPerView={3}
                        navigation
                        loop={true}
                        autoplay={false}
                        centeredSlides={true}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                    >
                        <SwiperSlide>
                            <div className="eventBox">
                                <img src="/assets/images/PowerEventR-min.jpg" alt='eventImg' />
                                <div className="eventContentBox">
                                    <h6 className="eventDate">28<span>March</span></h6>
                                    <h5 className="eventTitle">Power Talks</h5>
                                    <p>Engage with leaders in diplomacy and be inspired by their insights.</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        {/* <SwiperSlide>
                            <div className="eventBox">
                                <img src="/assets/images/ScholarsEventR-min.jpg" alt='eventImg' />
                                <div className="eventContentBox">
                                    <h6 className="eventDate">28<span>March</span></h6>
                                    <h5 className="eventTitle">OxfordMUN Innovation Challenges</h5>
                                    <p>Kickstart your MUN journey with unique challenges, enhancing team dynamics and critical
                                        thinking.</p>
                                </div>
                            </div>
                        </SwiperSlide> */}
                        <SwiperSlide>
                            <div className="eventBox">
                                <img src="/assets/images/Masquerade.jpg" alt='eventImg' />
                                <div className="eventContentBox">
                                    <h6 className="eventDate">28<span>March</span></h6>
                                    <h5 className="eventTitle">Masquerade Ball</h5>
                                    <p>A night of mystery and elegance, encouraging connections among delegates.</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="eventBox">
                                <img src="/assets/images/Global Village Gala-min.png" alt='eventImg' />
                                <div className="eventContentBox">
                                    <h6 className="eventDate">28<span>March</span></h6>
                                    <h5 className="eventTitle">Global Village Gala</h5>
                                    <p>Celebrate global cultures and unity through a vibrant evening of festivity.</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="eventBox">
                                <img src="/assets/images/Powerdrill-min.jpg" alt='eventImg' />
                                <div className="eventContentBox">
                                    <h6 className="eventDate">28<span>March</span></h6>
                                    <h5 className="eventTitle">Oxford Power Drill</h5>
                                    <p>Join the Oxford Power Drill, a competition where groups battle in endurance, strategy, and teamwork challenges for the ultimate award.</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="eventBox">
                                <img src="/assets/images/Gala Dinner.jpg" alt='eventImg' />
                                <div className="eventContentBox">
                                    <h6 className="eventDate">28<span>March</span></h6>
                                    <h5 className="eventTitle">Gala Dinner</h5>
                                    <p>Celebrate and network at the Gala Dinner, marking accomplishments and friendships, with insights from leaders in diplomacy and global affairs.</p>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>
            <section className="partnerSec">
                <div className="container-full">
                    <h2 className="secTitle">Partners</h2>

                    <Swiper breakpoints={{
                        1440: {
                            spaceBetween: 25,
                            slidesPerView: 9,
                        },
                        768: {
                            spaceBetween: 20,
                            slidesPerView: 7,

                        },
                        200: {
                            spaceBetween: 20,
                            slidesPerView: 3,
                        }
                    }}
                        modules={[Pagination, A11y, Autoplay]}
                        spaceBetween={25}
                        slidesPerView={9}
                        pagination={{ clickable: true }}
                        loop={true}
                        autoplay={false}
                        centeredSlides={true}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                    >
                        <SwiperSlide>
                            <div className="partnerLogo">
                                <img src="/assets/images/partner2.png" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="partnerLogo">
                                <img src="/assets/images/partner4.png" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="partnerLogo">
                                <img src="/assets/images/partner3.png" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="partnerLogo">
                                <img src="/assets/images/partner2.png" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="partnerLogo">
                                <img src="/assets/images/partner1.png" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="partnerLogo">
                                <img src="/assets/images/partner5.png" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="partnerLogo">
                                <img src="/assets/images/partner3.png" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="partnerLogo">
                                <img src="/assets/images/partner4.png" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="partnerLogo">
                                <img src="/assets/images/partner1.png" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="partnerLogo">
                                <img src="/assets/images/partner2.png" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="partnerLogo">
                                <img src="/assets/images/partner3.png" />
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>
            <section className="venueSpotSec">
                <div className="container">
                    <h2 className="secTitle">Venue Spotlight</h2>
                    <div className="venueSpot-inner">

                        <ImageGallery items={images}
                            autoPlay={true}
                            showFullscreenButton={false}
                            showPlayButton={false}
                            infinite={true} />


                        {/* <img src="/assets/images/venueSpotImg.png" /> */}
                        <div className="venueSpotContent">
                            <h2><span className="textUpper">Flame</span> University</h2>
                            <p>FLAME University in Pune, celebrated for its unparalleled academic achievements and commitment to all-around growth, is thrilled to be the vibrant host of this groundbreaking conference. Nestled amidst lush greenery, the university boasts cutting-edge facilities and a pulsating campus atmosphere, setting an idyllic stage for an immersive and dynamic experience that goes beyond the traditional confines of a Model UN. Join us for an adventure in intellectual exploration and personal development, where the energy is contagious and the opportunities boundless.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="testimonialSec">
                <div className="container-full">
                    <h2 className="secTitle">Testimonials</h2>

                    <Swiper breakpoints={{
                        1440: {
                            spaceBetween: 50,
                            slidesPerView: 4,
                            centeredSlides: false,
                        },
                        768: {
                            spaceBetween: 30,
                            slidesPerView: 3,

                        },
                        200: {
                            spaceBetween: 20,
                            slidesPerView: 1,
                        }
                    }}
                        modules={[Autoplay, A11y]}
                        spaceBetween={50}
                        slidesPerView={5}
                        loop={true}
                        autoplay={false}
                        centeredSlides={true}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                    >
                        <SwiperSlide>
                            <div className="testimonialImg">
                                <img src="assets/images/testimonial1.png" alt='home-img' />
                                <div className="textWrapper">
                                    <img src="/assets/images/videoSec.svg" alt='home-img' />

                                    <h6 className="titleWatch">Watch Story</h6>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="testimonialImg"><img src="assets/images/testimonial2.png" alt='home-img' />
                                <div className="textWrapper">
                                    <img src="/assets/images/videoSec.svg" alt='home-img' />
                                    <h6 className="titleWatch">Watch Story</h6>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="testimonialImg"><img src="assets/images/testimonial3.png" alt='home-img' />
                                <div className="textWrapper">
                                    <img src="/assets/images/videoSec.svg" alt='home-img' />
                                    <h6 className="titleWatch">Watch Story</h6>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="testimonialImg"><img src="assets/images/testimonial1.png" alt='home-img' />
                                <div className="textWrapper">
                                    <img src="/assets/images/videoSec.svg" alt='home-img' />
                                    <h6 className="titleWatch">Watch Story</h6>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="testimonialImg"><img src="assets/images/testimonial2.png" alt='home-img' />
                                <div className="textWrapper">
                                    <img src="/assets/images/videoSec.svg" alt='home-img' />
                                    <h6 className="titleWatch">Watch Story</h6>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="testimonialImg">
                                <img src="assets/images/testimonial1.png" alt='home-img' />
                                <div className="textWrapper">
                                    <img src="/assets/images/videoSec.svg" alt='home-img' />
                                    <h6 className="titleWatch">Watch Story</h6>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>
            <section className="reviewSec">
                <div className="container-full">

                    <Swiper

                        // install Swiper modules
                        modules={[Autoplay, Navigation, A11y, EffectFade]}
                        spaceBetween={0}
                        slidesPerView={1}
                        loop={true}
                        autoplay={false}
                        centeredSlides={true}
                        effect="fade"
                        navigation={true}
                    //onSwiper={(swiper) => console.log(swiper)}
                    //onSlideChange={() => console.log('slide change')}
                    >
                        <SwiperSlide>
                            <div>
                                <img src="/assets/images/7500students-min.jpg" alt='home-img' />
                                <div className="reviewContent">
                                    <h3 className="reviewTitle">7500+ Students Engaged</h3>
                                    {/* <p>Students Engaged</p> */}
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div>
                                <img src="/assets/images/Nationalities.jpg" alt='home-img' />
                                <div className="reviewContent">
                                    <h3 className="reviewTitle">40+ Nationalities Represented</h3>
                                    {/* <p> Nationalities Represented</p> */}
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div>
                                <img src="/assets/images/21Conferences-min.jpg" alt='home-img' />
                                <div className="reviewContent">
                                    <h3 className="reviewTitle">21 Conferences Held</h3>
                                    {/* <p> Conferences Held</p> */}
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div>
                                <img src="/assets/images/AcademicMentors -min.jpg" alt='home-img' />
                                <div className="reviewContent">
                                    <h3 className="reviewTitle">22+ Academic Mentors</h3>
                                    {/* <p> Academic Mentors</p> */}
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div>
                                <img src="/assets/images/7Countriesoperate-min.jpg" alt='home-img' />
                                <div className="reviewContent">
                                    <h3 className="reviewTitle">Operates in 7 Countries</h3>
                                    {/* <p>Operates in 7 Countries</p> */}
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>




                    {/* <img src="/assets/images/reviewImg.png" alt='home-img' />
                    <div className="reviewContent">
                        <h3 className="reviewTitle">Last year Summit</h3>
                        <p>OxfordMUN India is more than a conference; it's a movement towards nurturing informed</p>
                    </div> */}
                </div>
            </section>
            <section className="joinSec">
                <div className="container">
                    <div className="row">
                        <div className="column logoImage">
                            <img src="/assets/images/logoWhiteOx.png" alt='home-img' />
                        </div>
                        <div className="column content-wrapperCol">
                            <div className="content-wrapper">
                                <h5 className="contentTitle">Join the movement </h5>
                                <p>OxfordMUN India is more than a conference; it's a movement towards nurturing informed, empathetic, and capable future leaders. We invite you to be a part of this historic event, whether by participating, following its progress, or contributing to its objectives. Discover more about this transformative experience and how you can be a part of shaping a brighter future through the power of diplomacy and dialogue.</p>
                                <a href="https://bit.ly/OxfordMUNRegistration" class="registerBtn" target="_blank"> Download Brochure </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="callToAction">
                <div className="container  ">
                    <div className="  callToAction-inner">
                        <div className="row">
                            <div className="column callToActionLeft">
                                <h5>Make a Difference<br /> with Us</h5>
                                <p>Register Your School for OxfordMUN India 2024 Today!</p>
                            </div>
                            <div className="column callToActionRight">
                                <p>Are you ready to embark on an extraordinary journey of discovery, diplomacy, and development? OxfordMUN India 2024 is inviting schools from across the globe to register and secure their spot in one of the most prestigious Model United Nations conferences. This is a unique opportunity for students to enhance their understanding of global issues, develop critical thinking skills, and engage in meaningful debates on world affairs. </p>
                                <a href="https://bit.ly/OxfordMUNRegistration" className="registerBtn" target="_blank"> Register Now </a>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <section className="faqSec">

                <div className="container row faqInner">
                    <div className="faqInnerLeft">
                        <div className="faqInnerLeft-inner">
                            <img src="/assets/images/MU20-White-logo.png" alt="home-img" />
                            <p className="fs-18">Make a Difference with Us: Register Your School for OxfordMUN India 2024 Today!</p>
                            <p className="fs-18">Are you ready to embark on an extraordinary journey of discovery, diplomacy, and development? OxfordMUN India 2024 is inviting schools from across the globe to register and secure their spot in one of the most prestigious Model United Nations conferences. This is a unique opportunity for students to enhance their understanding of global issues, develop critical thinking skills, and engage in meaningful debates on world affairs.</p>
                            <a href="https://bit.ly/OxfordMUNRegistration" className="registerBtn " target="_blank"> Register Now </a>
                            <p className="fs-18 p-0">Still Have a question?  No worries.</p>

                            <div className="contactDetail">
                                <a href="mailto:madhur.oza@muniversiti.org " ><img src="/assets/images/email.svg" alt="home-img" />madhur.oza@muniversiti.org </a>
                                <a href="tel:7987519367" ><img src="/assets/images/whatsapp.svg" alt="home-img" />7987519367</a>
                            </div>
                            <div className="socialLinks">

                                <a href=" https://www.instagram.com/mu20.world?igsh=MThkY3NkeGJ3bHNtaA==" target="_blank">
                                    <img src="/assets/images/insta.svg" alt="home-img" /></a>
                                <a href="https://www.linkedin.com/company/mu20/" target="_blank">
                                    <img src="/assets/images/linkdine.svg" alt="home-img" /></a>
                            </div>
                        </div>
                    </div>
                    <div className="faqInnerRight">
                        <h2 className="secTitle">FAQs</h2>
                        <div class="faqBlock">
                            <Accordion allowZeroExpanded>
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            <h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                                Where is the venue for OxfordMUN India?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p className="faqContent">FLAME University Pune</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                            What are the dates for OxfordMUN India?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>August 9-11</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                            How can I register for OxfordMUN India?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>Visit <a href="https://bit.ly/OxfordMUNRegistration">Link</a> to secure your spot and embark on an unforgettable journey of Discovery, Diplomacy and Development.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                            What are the fees for participating in OxfordMUN India?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>- School Registration Fee: INR 5,000 (Includes one teacher representative without any additional charge)</p>
                                        <p> - Delegate Fee for MU20 Member Schools: INR 25,000 per delegate</p>
                                        <p> - Delegate Fee for Non-Member Schools: INR 30,000 per delegate</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                            Who can participate in OxfordMUN India?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>Students from Grade 9th to 12th</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                            Will accommodation be provided for participants?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>Yes, the participants and their teacher coordinators would be provided with accommodation at FLAME University Pune.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                            How can I get to the venue from the airport/train station?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>Convenient pickup and drop-off services from local stations and airports would be provided to participants.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                            Who can I contact for more information or assistance?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>Madhur Oza <a href="tel:+91-7987519367"> +91-7987519367 </a>&nbsp;&nbsp;<a href="mailTo:madhur.oza@muniversiti.org">madhur.oza@muniversiti.org</a> </p>
                                        <p>Ashwin Samuel <a href="tel:+91-9424536621"> +91-9424536621 </a>&nbsp;&nbsp;<a href="mailTo:connect@mu20.co">connect@mu20.co</a>
                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                            </Accordion>

                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default Bootcamp
