import React, { Component, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './header.css'  ;
import {FlapNav} from 'flap-nav';

const data = require('../../../data.json'); 






const Header = () => {

    const [scrolltopdata, setscrolltopdata] = useState('');

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY < 15) {
                setscrolltopdata('');
            } else {
                setscrolltopdata('scrolled');
            }
        });
    }, [])

  return (
       
    <header>  
        <div className={`main-header ${scrolltopdata}`}>
            <div className="site-logo">
            <a title="" href="/"><img src="/assets/images/MU20-White-logo.png" /></a>
            </div>

            <div className="navbar">
                {/* <ul className="navbar-nav">
                    <li className="navbar-item"><a href="#" onClick={navigate('/aboutus')} className="nav-link">About Us</a></li>
                    <li className="navbar-item"><a href="#" className="nav-link">Programs</a></li>
                    <li className="navbar-item"><a href="#" className="nav-link">Network School</a></li>
                </ul> */}
                <FlapNav data={data} />
                <div className="header-btn">
                    {/* <button href="https://bit.ly/OxfordMUNRegistration" target="_blank" className="join" > Join our Community </button> */}
                    {/* <a href="https://bit.ly/OxfordMUNRegistration" className="registerBtn fw-400" target="_blank"> Register Now </a> */}
                </div>

            </div>
            
        </div>
    </header>

  )
}

export default Header

