import React, { useState } from 'react';
import './Home.css';
import Header from './Header/Header';
import Footer from './Footer/Footer';



import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
// ----------------Swiper slider import------------------
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import "swiper/css/effect-fade";

function NetworkSchool() {


    const [formData, setFormData] = useState({
        personName: '',
        email: '',
        enquiry: '',
        schoolName: '',
        schoolLocation: '',
        contactNumber: '',
        message: ''
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();        
        const validationErrors = validateForm(formData);        

        //if (Object.keys(validationErrors).length === 0) {
            if (true) {
            try {                
                const response = await fetch('http://35.154.189.99:6000/user', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(formData)
                });
                // Handle response
            } catch (error) {
                console.error('Error submitting form:', error);
            }
        } else {
            setErrors(validationErrors);
        }
    };

    const validateForm = (data) => {
        let errors = {};
        if (!data.personName.trim()) {
            errors.personName = 'Name is required';
        }
        if (!data.email.trim()) {
            errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(data.email)) {
            errors.email = 'Email is invalid';
        }
        if (!data.schoolName.trim()) {
            errors.schoolName = 'School name is required';
        }
        if (!data.schoolLocation.trim()) {
            errors.schoolLocation = 'School location is required';
        }
        if (!data.contactNumber.trim()) {
            errors.contactNumber = 'Contact number is required';
        } else if (!/^\d{10}$/.test(data.contactNumber)) {
            errors.contactNumber = 'Contact number is invalid';
        }
        // Add more validation rules for other fields
        return errors;
    };

    return (
        <>
            <Header />
            <section className="p-80-20 bgBlack network-school">
                <div className="container-full">
                    <div className="topHeading p-0">
                        <h6 className="fs-80 fw-600 textOrg pb-20">Network School</h6>
                        <p className="textWhite">The Oxford MUN, a beacon of diplomatic simulations worldwide, marks its groundbreaking entry into India at FLAME University, Pune, from 9th to 11th August 2024. Orchestrated by the MU20 School of Opportunity, this event is a fusion of Oxford's prestigious academic standards with the vibrant cultural diversity of India, offering an unparalleled MUN experience.
                        </p>
                    </div>
                </div>
            </section>

            <section className="p-80-20 bglightBlack school-logo boderGradient">
                <div className="container">
                    <div className="school-logo-outer">
                        <div className="bgwhite">
                            <img src="assets/images/networkPage/school-logo.png" alt="schoollogo" />
                        </div>
                        <div className="bgwhite">
                            <img src="assets/images/networkPage/dhilepublic-logo.png" alt="schoollogo" />
                        </div>
                        <div className="bgwhite">
                            <img src="assets/images/networkPage/school-logo.png" alt="schoollogo" />
                        </div>
                        <div className="bgwhite">
                            <img src="assets/images/networkPage/dhilepublic-logo.png" alt="schoollogo" />
                        </div>
                        <div className="bgwhite">
                            <img src="assets/images/networkPage/school-logo.png" alt="schoollogo" />
                        </div>
                        <div className="bgwhite">
                            <img src="assets/images/networkPage/dhilepublic-logo.png" alt="schoollogo" />
                        </div>
                        <div className="bgwhite">
                            <img src="assets/images/networkPage/school-logo.png" alt="schoollogo" />
                        </div>
                        <div className="bgwhite">
                            <img src="assets/images/dhilepublic-logo.png" alt="schoollogo" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="p-80-20 bgBlack  pastProgVideoSec testimonialvideo">
                <div className="container-full">
                    <div className="topHeading">
                        <h2 className="fs-80 fw-600 textWhite">Video testimonials</h2>
                    </div>
                </div>

                <Swiper className="testimonialvideoInner" breakpoints={{
                    1440: {
                        spaceBetween: 30,
                        slidesPerView: 5,
                    },
                    768: {
                        spaceBetween: 20,
                        slidesPerView: 3,

                    },
                    200: {
                        spaceBetween: 20,
                        slidesPerView: 1,
                    }
                }}
                    modules={[Pagination, A11y, Autoplay]}
                    spaceBetween={30}
                    slidesPerView={5}
                    pagination={{ clickable: true }}
                    loop={true}
                    autoplay={false}
                    centeredSlides={true}
                >
                    <SwiperSlide>
                        <div className="pastProgVideos">
                            <video className="progVideos " muted>
                                <source src="assets/videos/video1.mov" type="video/mp4" />
                                <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                            </video>
                            {/* <img src="assets/images/homeImage/pastProgActive.png" alt="networkSch" /> */}
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="pastProgVideos">
                            <video className="progVideos " muted>
                                <source src="assets/videos/video2.mov" type="video/mp4" />
                                <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                            </video>
                            {/* <img src="assets/images/homeImage/pastProgActive.png" alt="networkSch" /> */}
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="pastProgVideos">
                            <video className="progVideos " muted>
                                <source src="assets/videos/video3.mov" type="video/mp4" />
                                <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                            </video>
                            {/* <img src="assets/images/homeImage/pastProgActive.png" alt="networkSch" /> */}

                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="pastProgVideos ">
                            <video className="progVideos " muted>
                                <source src="assets/videos/video1.mov" type="video/mp4" />
                                <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                            </video>
                            {/* <img src="assets/images/homeImage/pastProgActive.png" alt="networkSch" /> */}

                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="pastProgVideos  ">
                            <video className="progVideos " muted>
                                <source src="assets/videos/video2.mov" type="video/mp4" />
                                <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                            </video>
                            {/* <img src="assets/images/homeImage/pastProgActive.png" alt="networkSch" /> */}

                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="pastProgVideos  ">
                            <video className="progVideos " muted>
                                <source src="assets/videos/video3.mov" type="video/mp4" />
                                <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                            </video>
                            {/* <img src="assets/images/homeImage/pastProgActive.png" alt="networkSch" /> */}

                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="pastProgVideos  ">
                            <video className="progVideos " muted>
                                <source src="assets/videos/video1.mov" type="video/mp4" />
                                <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                            </video>
                            {/* <img src="assets/images/homeImage/pastProgActive.png" alt="networkSch" /> */}

                        </div>
                    </SwiperSlide>
                </Swiper>

            </section>

            <section className="programsSec bglightBlack  p-80-20 benefits">
                <div className="container">
                    <div className="program-outer">
                        <div className="program-innerRight">
                            <h3 className="fs-34 fw-600 pb-20 textOrg ">Benefits of being an MU20 Member Schools</h3>
                            <p className="fs-16 fw-400 textWhite">By connecting students from various backgrounds and schools, our flagship programmes engage students and actively involve them in the learning and development process.</p>

                            <h3 className="fs-34 fw-600 pb-20 textOrg ">Free Structure</h3>
                            <ul className="freestructure">
                                <li>
                                    <p className="fs-16 fw-400 textWhite">  INR 25,000 (including GST) per annum </p>
                                </li>
                                <li>
                                    <p className="fs-16 fw-400 textWhite"> Timely yearly payment of the AFS Member school is mandatory</p>
                                </li>
                                <li>
                                    <p className="fs-16 fw-400 textWhite">AFS Member School Fee has to be paid every year (April of each year)</p>
                                </li>
                                <li>
                                    <p className="fs-16 fw-400 textWhite">INR 25,000 (including GST) per annum</p>
                                </li>
                            </ul>

                            <a href="#" className="registerBtn">Check Out The Calendar 2023-24 </a>
                            <a href="#" className="download-brochure"><img className="widthAuto"
                                src="assets/images/networkPage/download-logo.svg" alt="iconBtn" />Download Brochure</a>
                        </div>

                        <div className="program-innerLeft">
                            <img src="/assets/images/pillarPage/progarm.png" alt="pillarProgram" />
                        </div>

                    </div>

                </div>
            </section>

            <section className="p-80-20 bgBlack joinour-chain">
                <div className="container">
                    <div className="contactPopup bglightBlack ">
                        <div className="contactDetail-outer">
                            <div className="contactDetail">
                                <h3 className="fs-42 fw-700 textWhite pb-20">Join our chain of Schools</h3>
                                <p className="fs-18 fw-400 textWhite pb-20">Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the
                                    1500s, when an unknown printe</p>
                                <a className="textEmail fs-18 fw-400 textOrg" href="mailto:support@mu20.net"><span><img className="widthAuto"
                                    src="assets/images/homeImage/mailIcon.svg" alt="iconEmail" /> support@mu20.net</span>
                                </a>
                            </div>
                        </div>

                        <div className="contactForm">
                            {/* <form>
                                <label for="fname">Name</label>
                                <input type="text" id="fname" placeholder="Name" />

                                <label for="cars">You are inquiring as a</label>
                                <select name="inquiring" id="inquiring">
                                    <option value="inquiring">You are inquiring as a</option>
                                </select>


                                <label for="fname">School Name</label>
                                <input type="text" id="fname" placeholder="School Name (if you are a parent, kindly fill in your Ward's school name)" />

                                <div className="loaction">
                                    <label for="location">School Location</label>
                                    <input type="tel" id="school-lacation" placeholder="School Location" />

                                    <label for="contact">Contact number</label>
                                    <input type="tel" id="contact-number" placeholder="Contact number" />
                                </div>

                                <label for="email">Email</label>
                                <input type="email" id="email" placeholder="Email" />

                                <label for="message">Message</label>
                                <textarea type="textarea" placeholder="Add a message"></textarea>

                                <button type="submit" className="formSubmit">Submit</button>
                            </form> */}

<form onSubmit={handleSubmit}>
                                <label htmlFor="personName">Name</label>
                                <input
                                    type="text"
                                    id="personName"
                                    name="personName"
                                    placeholder="Name"
                                    value={formData.personName}
                                    onChange={handleChange}
                                />
                                {errors.personName && <div className="error">{errors.personName}</div>}

                                <label htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder="Email"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                                {errors.email && <div className="error">{errors.email}</div>}

                                <label htmlFor="enquiry">Enquiry</label>
                                <input
                                    type="text"
                                    id="enquiry"
                                    name="enquiry"
                                    placeholder="Enquiry"
                                    value={formData.enquiry}
                                    onChange={handleChange}
                                />

                                <label htmlFor="schoolName">School Name</label>
                                <input
                                    type="text"
                                    id="schoolName"
                                    name="schoolName"
                                    placeholder="School Name"
                                    value={formData.schoolName}
                                    onChange={handleChange}
                                />
                                {errors.schoolName && <div className="error">{errors.schoolName}</div>}

                                <label htmlFor="schoolLocation">School Location</label>
                                <input
                                    type="text"
                                    id="schoolLocation"
                                    name="schoolLocation"
                                    placeholder="School Location"
                                    value={formData.schoolLocation}
                                    onChange={handleChange}
                                />
                                {errors.schoolLocation && <div className="error">{errors.schoolLocation}</div>}

                                <label htmlFor="contactNumber">Contact Number</label>
                                <input
                                    type="tel"
                                    id="contactNumber"
                                    name="contactNumber"
                                    placeholder="Contact Number"
                                    value={formData.contactNumber}
                                    onChange={handleChange}
                                />
                                {errors.contactNumber && <div className="error">{errors.contactNumber}</div>}

                                <label htmlFor="message">Message</label>
                                <textarea
                                    id="message"
                                    name="message"
                                    placeholder="Add a message"
                                    value={formData.message}
                                    onChange={handleChange}
                                ></textarea>

                                <button type="submit" className="formSubmit">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default NetworkSchool

